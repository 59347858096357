import React, { useState } from "react";
import { genLang } from "../../composables/genLang";
import { Dialog, DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const BACKEND1 = process.env.REACT_APP_API_URLX;

export function AddToNonCompletedView({ ticketId, setMessageDoneAction, getAllDoneActionsAftersalesList }) {
  const [open, setOpen] = useState(false);
  const handleSubmit = () => {
    const obj = { ticketid: ticketId, assigneeId: "", groupId: "64187" };
    axios
      .post(BACKEND1 + "/clients/ticketgroupsandusers", obj)
      .then((res) => {
        setOpen(false);
        const groupName = "Actions après vente non complete"
        const message = {
            en: `Group ${groupName} is assigned to ticket ${ticketId}`,
            fr: `Le groupe ${groupName} est assigné au ticket ${ticketId}`,
        }
        const locObj = { stateName: "assignTicketFormtxt", stateVal: genLang(message) };
        setMessageDoneAction(locObj);
        toast.success(
          genLang({
            en: "Assigned to non-completed view",
            fr: "Assigné à la vue non-complétée",
          })
        );
        getAllDoneActionsAftersalesList();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          genLang({
            en: "Error",
            fr: "Erreur",
          })
        );
      });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: "0.5rem" }} />
        {genLang({
          en: "Assign to non-completed view",
          fr: "Assigner à la vue non-complétée",
        })}
      </button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <p>
            {genLang({
              en: `Are you sure you want to assign folder ${ticketId} to the non-completed view?`,
              fr: `Êtes-vous sûr de vouloir assigner le dossier ${ticketId} à la vue non-complétée?`,
            })}
          </p>
          <div style={{ display: "flex", gap: "1rem", alignItems: "baseline" }}>
            <button
              type="button"
              className="btn btn-danger btn-block"
              onClick={() => setOpen(false)}
            >
              {genLang({
                en: "Cancel",
                fr: "Annuler",
              })}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={handleSubmit}
            >
              {genLang({
                en: "Yes",
                fr: "Oui",
              })}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
