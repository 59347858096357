import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    UncontrolledTooltip,
    UncontrolledPopover,
    PopoverBody,
    PopoverHeader,
    UncontrolledAlert,
    Spinner,
    Popover,
    ButtonGroup,
    Button
    // Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faTimes,
    faTags,
    faPhone, 
    faEnvelope,
    faUserCircle,
    faEraser,
    faSearch,
    faRedo,
    faRobot,
    faInfoCircle,
    faPlus,
    faEyeSlash,
    faEdit,
    faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import {
    BrowserRouter as Router,
    Redirect,
  } from "react-router-dom";
import moment from "moment";
import _, { update } from 'lodash';
import Table from '../../../utils/MaterialTable';
import DashboardElement from '../../../../utils/DashboardElement';
import ActionBar from "./ActionBar";
import EditLeadModal from "./EditLeadModal";
import CreateAgentModal from "./CreateAgentModal";
import PriorityIconsToggle from "./PriorityIconsToggle";
import axios from 'axios';
import { API_DOMAIN } from '../../../../../appConfig'; 
import { axiosDefaults } from '../../../../../appConfigaxios'; 
import  Loader from "../../../../../loader";
import { PartyModeSharp } from "@material-ui/icons";
import AlertIconsToggle from "./AlertIconsToggle";
import IconButton from "../../../IconButton/IconButton";
const BACKEND1 = process.env.REACT_APP_API_URLX;

export default class CenterSection extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data:[],
            rappels: [],
            showEditLead: false,
            error: false,
            loading: false,
            tableView: "lead",
            agentOptions: [],
            domaineOptions: [],
            macrosOptions: [],
            selectedLead: {
                id: '',
                domaineLead: {label: ''},
                created: '',
                updated_at:'',
                ticket_id: '',
                user: '',
                agentId: '',
                status: '',
                id_client: '',
                contact: '',
                definition: '',
                fromtrack: '',
                id_contact: '',
                lead_type: '',

            },
            filterAgent: '',
            filterStatus: '',
            filterDomaine: "",
            filterProvince: "",
            filterOther: '',
            filterOtherText: '',
            filterLoading: false,
            selectedCheckboxes: [],
            //dataRawList:[],
            // leadstatus:"",
            // leadthermo:"",
            // leaddomain:"",
            // leaduseragent:"",
            dataRawList:{neoleads:[]},  fieldName:"", fieldName2:"", fieldVal:"",
            isLoaderOpen:false, //toastrMessage: '', toastrType: '', toastrDuration: 6000,
            // dataAgentsDomains:{}, selectedAgent:{},
        };
    }

    componentDidMount(){
        axiosDefaults(null);
        this.fetchData();
        this.fetchMacros();
        this.fetchRappels();
        this.interval = setInterval(this.fetchFilterData, 100000);
        // this.interval = setInterval(this.fetchFilterData, 10000);

        /*axios.get(
            BACKEND1+'/leads',
            //BACKEND1+'/leads/leads',
            {
                params: {
                  agentTransfer: "yes"
                    // showtrans: 3
                    // page:2,
                    // limit:6,
                    // newlead:1 pour obtenir des un nouveau lead
                },
                //params:params, 
            }
        )
        .then((status) => {
            let {data} = status.data;
            //const dataAll= this.state.dataRawList; 
            if(data && data.agentList){
                          
                /
                const domaineOptions = data.domainList.map(i => {
                    return {label: i.label_fr, value: i.label_fr, id: i.id, name: i.name}});
                // const domaineOptions = data.domainList.map(i => {
                //     return {en: i.label_en, fr: i.label_fr, value: i.name, id: i.id, name: i.name}});
                /
      
                const agentOptions = data.agentList.map(i => {
                    return {label: i.label, value: i.label, agentId: i.value}
                });
                // agentOptions.unshift({label: 'Aucun Agent/Enlever Agent', value: null, name: null, agentId: null})
                agentOptions.push({label: 'Aucun/None', value: 'Aucun/None', agentId: ''});
      
                //const dataAll= this.state.dataRawList; 
                ///dataAll.neoleads.push(...data.neoleads);
                ///dataAll.neoleads.push(arr);
                //dataAll.neoleads=arr;
                this.setState({
                     dataAgentsDomains: data,
                    // domaineOptions: domaineOptions,
                    agentOptions: agentOptions
                });
                this.setState({ ["isLoaderOpen"]: false});  
                //thermo:e.thermo,
            }
        }
        // ,(error) =>{
        //     // CHANGE BACK IN PROD
        //     this.setState({ ["isLoaderOpen"]: false});  
        //     this.setState({
        //         redirect:'/login'
        //     })
        // }
        ).catch(function(error){ 
          console.log("error object:", error.response.data)
          this2.setState({postErrorMsg: error.response.data}) 
        });*/
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    fetchRappels = () => {
        axios.get(
            BACKEND1+'/leads/leads',
            {
                params: {
                    //src: "desk",
                    eventcal: "yes",
                    showtrans: 3
                    // page:2,
                    // limit:6,
                    // newlead:1 pour obtenir des un nouveau lead
                },
                //headers: {
                    //'Content-Type': 'application/json',
                    // 'x-jwt-token': localStorage.getItem('token'),
                    //'auth-id': localStorage.getItem('user_id'),
                    //'Authorization': "Bearer " + localStorage.getItem('token')
                //}
            }
        ).then(({data}) => {
            const domaineOptions = data.data.domainList.map(i => {
                return {label: i.label_fr, value: i.label_fr, id: i.id, name: i.name}});
            const rappels = data.data.neoleads.map(i => {
                const dom = () => {
                    try {
                       return domaineOptions.find(j => j.name === i.domaine).value
                    } catch { return null}
                }
                return {
                    ...i,
                    // domain: i.domaine,
                    definition: JSON.parse(i.definition),
                    domaineLead: dom(),
                    user: i.agent,
                    agentId: i.id_user,
                }
            });
            // // console.log(rappels)
            this.setState({rappels: rappels})
        })
    }

    fetchMacros = () => {
        axios.get(
            BACKEND1+'/leads/leads',
            {
                params: {
                    onlyMacros: "yes",
                    showtrans: 3
                },
            }
        ).then(({data}) => {
            console.log(data.data.macros)
            const macrosOptions = data.data.macros.filter(i => i.type !== 'leadReason').map(i => {
                return {label: i.title, value: i.id, text: i.value}
            })
            // // console.log(rappels)
            this.setState({macrosOptions: macrosOptions})
        })
    }

    fetchFilterData = () => {
        this.setState({filterLoading: true});
        let {filterAgent, filterStatus, filterDomaine, filterOther, filterOtherText, filterProvince} = this.state
        let params = {showtrans: 3}
        if (filterAgent) params.userId = filterAgent;
        if (filterStatus) params.status = filterStatus;
        if (filterDomaine) params.domaine = filterDomaine;
        if (filterOther && filterOtherText) {
            params.fieldName = filterOther;
            params.fieldVal = filterOtherText
        }
        if (filterProvince) params.province = filterProvince;
        console.log(params)
        axios.get(
            BACKEND1+'/leads/leads',
            {
                params,
            }
        )
        .then((status) => {
            let {data} = status.data;
            this.setState({filterLoading: false})
            if(data && data.neoleads){
                let {neoleads} = data;
                const domaineOptions = data.domainList.map(i => {
                    return {label: i.label_fr, value: i.label_fr, id: i.id, name: i.name}});
                const agentOptions = data.agentList.map(i => {
                    return {label: i.label, value: i.label, agentId: i.value}
                });
                let arr = _.map(
                    neoleads,
                    (e, i) => {
                        const dom = () => {
                            try {
                               return domaineOptions.find(i => i.name === e.domaine).value
                            } catch { return null}
                        }
                        let  subject="Pas de sujet/No subject";
                        if (e.definition !== null && e.definition !== undefined){ 
                            let myObj = undefined;
                            if (e.definition.via !== undefined){
                                //myObj = e.definition;
                                myObj = JSON.parse(JSON.stringify(e.definition));
                                e.definition=JSON.stringify(e.definition);
                                //// console.log("IS OBJECT definition ", e.definition.via);
                            }else{
                                myObj = JSON.parse(e.definition);
                            }
                            //const myObj = JSON.parse(e.definition);
                            //subject = JSON.parse(e.definition).ticket_subject;
                            if(myObj!==undefined && myObj!==null && myObj.ticket_subject!==undefined && myObj.ticket_subject!==null){
                                subject = myObj.ticket_subject;
                            }
                        }
                        const {domaine, agent, id_user, definition, ...rest } = e;
                        return {
                            ...rest,
                            domaineLead: dom(),
                            updated_at:moment().format('LLL'),
                            user: e.agent,
                            agentId: e.id_user,
                            definition: JSON.parse(e.definition),
                            subject: subject 
                        }
                    }
                );

                this.setState({
                    data: arr,
                    domaineOptions: domaineOptions,
                    agentOptions: agentOptions
                });
            }
        })
    }

    fetchData = (obj) => {
        // const objBig  = this.state.dataRawList;
        const fieldName=this.state.fieldName;
		const fieldVal=this.state.fieldVal;
        const leadstatus=this.state.leadstatus;
        // let statusXObj={page:page, page:page, fieldName:fieldName, fieldVal:fieldVal};
        // console.log("fetchData-fetchData received ", obj);
        let actionName="";
        let params= {
            //src: "desk",
            showtrans: 3
            // page:2,
            // limit:6,
            // newlead:1 pour obtenir des un nouveau lead
        };

        //const actionName=obj.actionName;
        if(obj!==undefined && obj!==null){
            actionName=obj.objectName; 
            // console.log("actionName set", actionName,  params);
        }
        if(actionName!==undefined && actionName!==null && actionName!==""){             
            //this.fetchData("loadNextPage");
            params= {
                //src: "desk",
                showtrans: 3, page:obj.page,
                fieldName: obj.fieldName, 
                fieldVal:obj.fieldVal
                // page:2,
                // limit:6,
                // newlead:1 pour obtenir des un nouveau lead
            };
            // console.log("params-params-params set", params);
        }else{
            if(fieldName!==null && fieldName!=="" && fieldVal!==null && fieldVal!==""){                     
                //this.fetchData("loadNextPage");
                params= {
                    //src: "desk",
                    showtrans: 3, page:1,
                    fieldName: fieldName, 
                    fieldVal: fieldVal
                    // page:2,
                    // newlead:1 pour obtenir des un nouveau lead
                };
                // console.log("else-params-params-params set", params);
            }
        }   
        // if(obj===undefined){
        //     console.log("actionName set undefined action maybe a refresh 140s ", leadstatus);
        //     if(leadstatus!==null && leadstatus!==""){
        //         console.log("action going to server stop ", leadstatus);
        //         return false;
        //     }
        // }
        axios.get(
            BACKEND1+'/leads/leads',
            {
                // params: {
                //     //src: "desk",
                //     showtrans: 3
                //     // page:2,
                //     // limit:6,
                //     // newlead:1 pour obtenir des un nouveau lead
                // },
                params:params,
                //headers: {
                    //'Content-Type': 'application/json',
                    // 'x-jwt-token': localStorage.getItem('token'),
                    //'auth-id': localStorage.getItem('user_id'),
                    //'Authorization': "Bearer " + localStorage.getItem('token')
                //}
            }
        )
        .then((status) => {
            let {data} = status.data;
            //const dataAll= this.state.dataRawList; 
            if(data && data.neoleads){
                let {neoleads} = data;
                if(actionName!==undefined && actionName!==null && actionName==="loadNextPage"){
                    const dataAll= this.state.dataRawList; 
                    dataAll.neoleads.push(...data.neoleads);
                    //// console.log("222-POSTACTION data ", data, response.NeoVentes.data); 
                    dataAll.currentPage=parseInt(data.currentPage);
                    //this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
                    this.setState({ ["dataRawList"]: dataAll});
                    neoleads=dataAll.neoleads;
                    // console.log("daaaaaaaaata status level 1", neoleads);
                }else if(actionName!==undefined && actionName!==null && actionName==="loadNextPageRedata"){

                    this.setState({ ["dataRawList"]: data}); 
                    // console.log("daaaaaaaaata status level 2");
                }
                else if(actionName!==undefined && actionName!==null && actionName==="loadNextPageReset"){

                    this.setState({ ["dataRawList"]: data}); 
                    // console.log("daaaaaaaaata status level 3");
                }else{
                    this.setState({ ["dataRawList"]: data}); 
                    // console.log("daaaaaaaaata status level 4", data);
                }
                    const domaineOptions = data.domainList.map(i => {
                    return {label: i.label_fr, value: i.label_fr, id: i.id, name: i.name}});
                // const domaineOptions = data.domainList.map(i => {
                //     return {en: i.label_en, fr: i.label_fr, value: i.name, id: i.id, name: i.name}});
                const agentOptions = data.agentList.map(i => {
                    return {label: i.label, value: i.label, agentId: i.value}
                });
                // agentOptions.push({label: 'Aucun/None', value: 'Aucun/None', agentId: ''});
                // agentOptions.unshift({label: 'Aucun Agent/Enlever Agent', value: null, name: null, agentId: null})
                let arr = _.map(
                    neoleads,
                    (e, i) => {
                        // // console.log("JSON", JSON.parse(e.definition))
                        // const status = !e.agent ? '2' : '1'
                        const dom = () => {
                            try {
                               return domaineOptions.find(i => i.name === e.domaine).value
                            } catch { return null}
                        }
                        let  subject="Pas de sujet/No subject";
                        //if (e.definition !== null && e.definition !== undefined) subject = JSON.parse(e.definition).ticket_subject;
                        if (e.definition !== null && e.definition !== undefined){ 
                            let myObj = undefined;
                            if (e.definition.via !== undefined){
                                //myObj = e.definition;
                                myObj = JSON.parse(JSON.stringify(e.definition));
                                e.definition=JSON.stringify(e.definition);
                                //// console.log("IS OBJECT definition ", e.definition.via);
                            }else{
                                myObj = JSON.parse(e.definition);
                            }
                            //const myObj = JSON.parse(e.definition);
                            //subject = JSON.parse(e.definition).ticket_subject;
                            if(myObj!==undefined && myObj!==null && myObj.ticket_subject!==undefined && myObj.ticket_subject!==null){
                                subject = myObj.ticket_subject;
                            }
                        }
                        const {domaine, agent, id_user, definition, ...rest } = e;
                        return {
                            ...rest,
                            domaineLead: dom(),
                            updated_at:moment().format('LLL'),
                            user: e.agent,
                            agentId: e.id_user,
                            definition: JSON.parse(e.definition),
                            subject: subject 
                        }
                    }
                );

                const dataAll= this.state.dataRawList; 
                //dataAll.neoleads.push(...data.neoleads);
                //dataAll.neoleads.push(arr);
                dataAll.neoleads=arr;
                this.setState({
                    data: arr,
                    //dataRawList: arr,
                    dataRawList:JSON.parse(JSON.stringify(arr)), 
                    dataRawList:JSON.parse(JSON.stringify(dataAll)), 
                    domaineOptions: domaineOptions,
                    agentOptions: agentOptions
                });
                this.setState({ ["isLoaderOpen"]: false});  
                //thermo:e.thermo,
            }
        },(error) =>{
            // CHANGE BACK IN PROD
            this.setState({ ["isLoaderOpen"]: false});  
            this.setState({
                redirect:'/login'
            })
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////
    //loadNextPage  const objList  = this.state.dataRawList.data;
	// loadNextPage = (event) => {
	// 	//const { t } = this.props;
	// 	//this.setState({ [event.target.name]: event.target.value });
	// 	const objBig  = this.state.dataRawList;
    //     // console.log("dataRawList-dataRawList", objBig);
	// 	if(objBig!==null && objBig.currentPage!==undefined){}else{ return false; }
	// 	const currentPage=objBig.currentPage;
	// 	const limit=objBig.limit;
	// 	const maxpage=objBig.maxpage;
	// 	const offset=objBig.offset;
	// 	const records=objBig.records;
	// 	const total=objBig.total;
	// 	const fieldName=this.state.fieldName;
	// 	const fieldVal=this.state.fieldVal;
	// 	//limit: 5 maxpage: 5442 offset: 0 records: 5 total: "27208" 
	// 	if((currentPage+1)>maxpage){ return false; }

	// 	const page=(currentPage+1);
	// 	//this.setState({ ["isLoaderOpen"]: true});  
	// 	const this2=this; 
	// 	//const statusXObj={statusList:"payed,paid-b2b", conflict:1};
	// 	const statusXObj={objectName:"loadNextPage",page:page, fieldName:fieldName, fieldVal:fieldVal};
    //     this.setState({ ["isLoaderOpen"]: true});   
    //     this.fetchData(statusXObj);

	// };

	// loadNextPageRedata = (event) => {
	// 	//const { t } = this.props;
	// 	//this.setState({ [event.target.name]: event.target.value });
	// 	//const objBig  = this.state.dataRawList;
	// 	 const fieldName=this.state.fieldName;
	// 	 const fieldVal=this.state.fieldVal;
	// 	// //limit: 5 maxpage: 5442 offset: 0 records: 5 total: "27208" 
	// 	// if((currentPage+1)>maxpage){ return false; }
	// 	// const page=(currentPage+1);
	// 	if(fieldName===null || fieldName==="" || fieldVal===null || fieldVal===""){
	// 		//this.showToast("Erreur Champs et Valeur/ Error Field and value", "error");  
	// 		return false;
	// 	}

    //     const statusXObj={objectName:"loadNextPageRedata", page:1, fieldName:fieldName, fieldVal:fieldVal};
    //     //this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
    //     this.setState({ ["dataRawList"]: {data:[]}}); 
    //     this.setState({ ["isLoaderOpen"]: true});    
    //     this.fetchData(statusXObj);

	// };

	// loadNextPageReset = (event) => {
	// 	//const { t } = this.props;
	// 	//this.setState({ [event.target.name]: event.target.value });
	// 	//const objBig  = this.state.dataRawList;
	// 	 const fieldName=this.state.fieldName;
	// 	 const fieldVal=this.state.fieldVal; 
	// 	this.setState({  ["fieldName"]: "", ["fieldVal"]: "",});  
	// 	//this.setState({ ["fieldName"]: "", ["fieldVal"]: "",});  
	// 	const this2=this; 
	// 	//const statusXObj={statusList:"payed,paid-b2b", conflict:1};
	// 	// const statusXObj={statusList:"payed,paid-b2b", conflict:2, page:1
	// 	// , fieldName:"", fieldVal:""};
    //     const statusXObj={objectName:"loadNextPageReset", page:1, fieldName:"", fieldVal:""}; 
    //     this.setState({ ["dataRawList"]: {data:[]}});
    //     this.setState({ ["isLoaderOpen"]: true});      
    //     this.fetchData(statusXObj);

	// };

	// handleChange = (event) => {
	// 	this.setState({ [event.target.name]: event.target.value });
	// };

    // handleChangeStatus = (event) => {
    //     //const status=event.target.dataset.status;
    //     const status = event.target.options[event.target.selectedIndex].dataset.status;
    //     const fieldName = event.target.options[event.target.selectedIndex].dataset.value;
	// 	//this.setState({ [event.target.name]: event.target.value });
    //     //console.log("Heeeeeeeeeeeeeeeellooooooooo ", status, event);
    //     //if(status!==undefined && status!==null && status!==""  
    //     if(status!==undefined && status!==null && 
    //     fieldName!==undefined && fieldName!==null){
    //         //this.setState({ [event.target.name]: event.target.value, ["fieldVal"]: status });
    //         this.setState({ ["fieldName2"]: event.target.value, ["fieldName"]: fieldName, ["fieldVal"]: status });
    //     }else{
    //         this.setState({ [event.target.name]: event.target.value });
    //     }
	// };

    
    ///////////////////////////////////////////////////////////////////////////////////////////

    handleCheckboxChange = (id) => {
        if (this.state.selectedCheckboxes.includes(id)) {
            const newSelectedCheckboxes = this.state.selectedCheckboxes.filter(i => i !== id)
            this.setState({selectedCheckboxes: newSelectedCheckboxes},() => console.log(this.state.selectedCheckboxes))
        } else {
            const newSelectedCheckboxes = [...this.state.selectedCheckboxes, id]
            this.setState({selectedCheckboxes: newSelectedCheckboxes}, () => console.log(this.state.selectedCheckboxes))
            // this.state.selectedCheckboxes.push(id)
        }
    }

    handleClearCheckboxes = () => {
        this.setState({selectedCheckboxes: []})
    }

    handleSelectAllCheckboxes = (e, rows) => {
        console.log(e.target.checked)
        if (e.target.checked){
            const newCheckboxes = rows.map(i => i.id) 
            this.setState({selectedCheckboxes: newCheckboxes});
        } else {
            this.setState({selectedCheckboxes: []});
        }
    }

    handleSelectChange = (e, field) => {
         //console.log(field, e);
        if (field === 'agent'){
            this.state.tableView === 'rappel' ? this.setState((prevState) => {
                // return {...prevState, selectedLead: {...prevState.selectedLead, user: e.label, agentId: e.agentId, status: '1'}}
                return {...prevState, selectedLead: {...prevState.selectedLead, status: '1', user: e.label, agentId: e.agentId}}
            }) :
            this.setState((prevState) => {
                // return {...prevState, selectedLead: {...prevState.selectedLead, user: e.label, agentId: e.agentId, status: '1'}}
                return {...prevState, selectedLead: {...prevState.selectedLead, user: e.label, agentId: e.agentId}}
            })}
        else if (field === 'thermo'){
            this.setState((prevState) => {
                return {...prevState, selectedLead: {...prevState.selectedLead, thermo: e.value}}
            })}
        else if (field === 'language'){
            this.setState((prevState) => {
                return {...prevState, selectedLead: {...prevState.selectedLead, language: e.value}}
            })} 
        else if (field === 'province'){
            this.setState((prevState) => {
                return {...prevState, selectedLead: {...prevState.selectedLead, province: e.value}}
            })}       
        else {
        this.setState((prevState) => {
            return {...prevState, selectedLead: {...prevState.selectedLead, domaineLead: e.value}}
        })}
    }

    handleSelectActionChange = (e) => {
        let currentAgent = this.state.agentOptions.find(i => i.agentId == this.state.selectedLead.agentId);
        // // console.log(currentAgent)
        switch (e.value) {
            case "0":
                return this.setState((prevState) => {
                    return {...prevState, selectedLead: {...prevState.selectedLead,
                        status:e.value
                    }}
                })
            case "5": 
                return this.setState((prevState) => {
                    return {...prevState, selectedLead: {...prevState.selectedLead,
                        status: e.value
                    }}
                })
            case "3": 
                return this.setState((prevState) => {
                        return {...prevState, selectedLead: {...prevState.selectedLead,
                            status: e.value
                        }}
                    })    
            case "1":
                return this.setState((prevState) => {
                    let user;
                    let agentId;
                    try {
                        user = currentAgent.value
                        agentId = currentAgent.agentId
                    } catch {
                        user = null;
                        agentId = null
                    }
                    return {...prevState, selectedLead: {...prevState.selectedLead,
                        status: e.value, user: user, agentId: agentId
                    }}
                }) 
        }
    }


    handleSetValueChange = (e, field) => {
        console.log("parent-handleSetValueChange", field, e);
       if (field && field !==undefined && field !==null){
        // this.setState((prevState) => {
        //     return {...prevState, selectedLead: {...prevState.selectedLead, province: e.value}}
        // }); 
        this.setState((prevState) => {
            return {...prevState, selectedLead: {...prevState.selectedLead, [""+field]: e.value}}
        });

        }
   }

    handleSubmit = () => {
        const {selectedLead, domaineOptions} = this.state;
        const found = this.state.data.find(i => i.id === selectedLead.id);
        const province= selectedLead.province; //this.state.province;
        const language= selectedLead.language; //this.state.language; 
        const duedate= selectedLead.duedate; 
        const followup= selectedLead.followup; // yes/no
        const isSame2 = (province === undefined || province===null || province==="") && (language === undefined || language===null || language==="");
        const isSame3 = (duedate === undefined || duedate===null || duedate==="") && (followup === undefined || followup===null || followup==="");
        // console.log(selectedLead)
        // console.log(found)
        const isSame = selectedLead.domaineLead === found.domaineLead && selectedLead.status === found.status && selectedLead.thermo === found.thermo && selectedLead.agentId === found.agentId;
        console.log("SAME ? ", isSame)
        if (isSame === false || isSame2===false || isSame3===false) {
            this.setState({loading: true});
            const domaine = selectedLead.domaineLead === null ? null : domaineOptions.find(i => i.label === selectedLead.domaineLead).name
            const id = selectedLead.status === "0" || selectedLead.status === "5" ? '-1' : selectedLead.agentId
            const agent = selectedLead.status === "0" || selectedLead.status === "5" ? '-1' : selectedLead.user
            let obj = {...selectedLead, agent: agent, id_user: id, domaine: domaine, setthermo: 'yes'}
            if(province !== undefined && province!==null && province!==""){
                obj.province=province;
            }
            if(language !== undefined && language!==null && language!==""){
                obj.language=language;
            }
            if(followup !== undefined && followup!==null && followup!==""){
                obj.followup=followup;
            }
            if(duedate !== undefined && duedate!==null && duedate!==""){
                obj.duedate=duedate;
            }
            const {domaineLead, user, agentId, editIcon, tableStatus, priorityIcons, alertIcons, subjectHover, ...rest} = obj
            console.log(rest)
            axios.post(
            BACKEND1+'/leads/leads/lead',
            rest,
           {
                params: {
                    //src: "desk",
                },
                //headers: {
                    //'Content-Type': 'application/json',
                    // 'x-jwt-token': localStorage.getItem('token'),
                    //'auth-id': localStorage.getItem('user_id'),
                    //'Authorization': "Bearer " + localStorage.getItem('token')
                //}
                }
            ).then(res => {
                if(res.status === 200){
                const user = agent === '-1' ? null : selectedLead.user
                const agentId = id === '-1' ? null : selectedLead.agentId
                this.setState({
                    data: this.state.data.map(i => i.id === this.state.selectedLead.id ? {...selectedLead, user: user, agentId: agentId} : {...i}),
                    rappels: this.state.rappels.map(i => i.id === this.state.selectedLead.id ? {...selectedLead, user: user, agentId: agentId} : {...i}),
                    loading: false
                });
                this.closeChangeLeadModal();
                this.fetchFilterData();
                } else {
                    this.setState((prevState) => {
                    return {...prevState, error: true, loading: false}
                })}
            })
            } 
    }

    handleTableStatus = (lead) => {
        switch (lead.status) {
            case "0":
                return this.genLang('automaticDistrubution')
            case "1":
                return this.genLang('attributed');
            case "2":
                return this.genLang('supported')
            case "3":
                return this.genLang('closed')    
            case "4":
                return this.genLang('notAutomaticDistribution')
            case "5":
                return this.genLang('cancelled')
            case "8":
                return this.genLang('ecommercesms')  

        }
    }

    
    openChangeLeadModal = (clickedLead) => {
        this.setState((prevState) => {
            return {...prevState, showEditLead: true, selectedLead: {...clickedLead}}
        })
    }

    handleChangePriority = (id, priority) => {
        axios.post(
            BACKEND1+'/leads/leads/lead',
            {
            setthermo: 'yes',
            thermo: priority,
            id: id
            },
            {
                params: {}
},
        ).then(res => {
            const {record} = res.data.data
            if (record) {
                const dom = () => {
                    try {
                       return this.state.domaineOptions.find(i => i.name === record.domaine).value
                    } catch { return null}
                }
                let  subject;
                if (record.definition !== null && record.definition !== undefined) subject = JSON.parse(record.definition).ticket_subject;
                const {domaine, agent, id_user, definition, ...rest } = record;
                const updatedLead =  {
                    ...rest,
                    domaineLead: dom(),
                    updated_at:moment().format('LLL'),
                    user: record.agent,
                    agentId: record.id_user,
                    definition: JSON.parse(record.definition),
                    subject: subject
                }
                this.setState((prevState) => {
                    return {...prevState, data: prevState.data.map(i => i.id === id ? {...updatedLead}: {...i})}
                })
            }
            
            this.fetchFilterData();
        })
    }

    handleChangeAlert = (id, priority) => {
        axios.post(
            BACKEND1+'/leads/leads/lead',
            {
            setpriority: 'yes',
            priority,
            id
            },
            {
                params: {}
},
        ).then(res => {
            const {record} = res?.data?.data;
            if (record) {
               const dom = () => {
                try {
                   return this.state.domaineOptions.find(i => i.name === record.domaine).value
                } catch { return null}
            }
            let  subject;
            if (record?.definition) subject = JSON.parse(record.definition).ticket_subject;
            const {domaine, agent, id_user, definition, ...rest } = record;
            const updatedLead =  {
                ...rest,
                domaineLead: dom(),
                updated_at:moment().format('LLL'),
                user: record.agent,
                agentId: record.id_user,
                definition: JSON.parse(record.definition),
                subject: subject
            }
            this.setState((prevState) => {
                return {...prevState, data: prevState.data.map(i => i.id === id ? {...updatedLead}: {...i})}
            }) 
            }
            
            this.fetchFilterData();
        })
    }

    closeChangeLeadModal = () => {
        this.setState({showEditLead: false, error: false, selectedLead: {
            id: '',
            domaineLead: {label: ''},
            created: '',
            updated_at:'',
            ticket_id: '',
            user: '',
            agentId: '',
            status: '',
            id_client: '',
            contact: '',
            definition: '',
            fromtrack: '',
            id_contact: '',
            lead_type: '',

        }})
    }

    toggleTableView = (tableView) => {
        this.setState({tableView: tableView})
    }

    genLang = (text) => {
        const {trad, lang} = this.props;
        return trad['neoleads'][text][lang]
    }

    renderLeadInfo = () => {
        const {selectedLead} = this.state
        if (!selectedLead) return '';
        if(!selectedLead.definition) return '';
        const {phone, user_email} = selectedLead.definition;
        const {contact} = selectedLead

        return (
            <div style={{width: '400px'}}>
                <div>
                    <FontAwesomeIcon icon={faUserCircle} color={"#A9A9A9"}/>{` ${!contact ? "" : contact}`}
                </div>
                <div style={{marginTop: '0.3rem'}}>
                    <FontAwesomeIcon icon={faPhone} color={"#A9A9A9"}/>{` ${!phone ? "": phone}`}
                </div>
                <div style={{marginTop: '0.3rem'}}>
                    <FontAwesomeIcon icon={faEnvelope} color={"#A9A9A9"}/>{` ${!user_email ? "" : user_email}`}
                </div>
            </div>
        )
    }

    // handleSelectLeadStatus = (event) => {
    //     // const {trad, lang} = this.props;
    //     // return trad['neoleads'][text][lang]
    //     const leadstatus=this.state.leadstatus;
    //     const leadthermo=this.state.leadthermo;
    //     const leaddomain=this.state.leaddomain;
    //     const leaduseragent=this.state.leaduseragent;
    //     const dataRawList=this.state.dataRawList;

    //     const val=event.value;
    //     this.setState({leadstatus: val});
    //     // console.log("handleSelectLeadStatus ", val);
    //     //data: arr, dataRawList:arr,
    //     const neoArr=[];
    //     for(let data of dataRawList.neoleads){
    //         //// console.log("AAAAAAAAAAAASSIGNT000 ", val, data.status, data.status);
    //         if((data.status===val && val!=="") || val===""){
    //             //// console.log("AAAAAAAAAAAASSIGNT ", data.status);
    //             neoArr.push(data);
    //         }
    //     }
    //     this.setState({data: neoArr});
    // }

    urlify = (text) => { 
        //var urlRegex = /(https?:\/\/[^\s]+)/g;
        //var urlRegex = /(https?:\/\/[^\s]+[^>]?)/g;
        var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
          return '<a href="' + url + '">' + url + '</a>';
        })
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }  
      

    getTexturlify = (notes) => {
        if(notes.length>0){
            const htmlBlock = this.urlify(notes);
            notes=htmlBlock; 
        }

        return notes;
    }

    getDueDate = (ticket_id) => {
        axios
          .get(BACKEND1 + "/fieldValue", {
            params: {
              ticket_id,
              field_id: "166",
              auth_id: localStorage.getItem("user_id"),
            },
          })
          .then((res) => {
            const duedate = res.data.data.value;
            const updatedSelectedLead = { ...this.state.selectedLead, duedate };
            this.setState({ selectedLead: updatedSelectedLead });
          })
          .catch((e) => {
            console.error(e);
          });
      };

    handleFilterSelectChange = (e, field) => {
        console.log(e)
        if (field === 'agent') {
            try{
               this.setState({filterAgent: e.agentId}, () => this.fetchFilterData());
            } catch {
                this.setState({filterAgent: ''}, () => this.fetchFilterData());
            }
        } else if (field === 'status'){
            try {
                this.setState({filterStatus: e.value}, () => this.fetchFilterData());
            } catch {
                this.setState({filterStatus: ''}, () => this.fetchFilterData())
                
            } }
            else if (field === "province") {
                try {
                  this.setState({ filterProvince: e.value }, () => this.fetchFilterData());
                } catch {
                  this.setState({ filterProvince: "" }, () => this.fetchFilterData());
                }
              }
            else if (field === 'domaine'){
                try {
                    this.setState({filterDomaine: e.name}, () => this.fetchFilterData());
                } catch {
                    this.setState({filterDomaine: ''}, () => this.fetchFilterData())
                    
                } 
        } else if (field === 'other'){
            try {
                this.setState({filterOther: e.value});
            } catch {
                this.setState({filterOther: '', filterOtherText: ''}, () => this.fetchFilterData())
            }
        }
    }

    // handleSelectChangeagent = (e, field) => {
    //     // console.log(e);
    //     //this.setState({ value: e, text: e.html});
    //     console.log("handleSelectChangeagent ", field,  e);  
    //     //.setState({ field: e.value,});
    //     this.setState({ [""+field]: e,}); //this.setState({ field[""+field]: e,});
    //     this.setState({ ["fieldName2"]: "invAgId", ["fieldName"]: "invAgId", ["fieldVal"]: e.agentId });
    //     //{label: 'Sarah Meunier', value: 'Sarah Meunier', agentId: '12455'}
    
    // }

    render(){

        // const agentOptions=this.state.agentOptions;
        // const handleSelectChangeagent=this.handleSelectChangeagent;
        // const selectedAgent=this.state.selectedAgent;

        if(this.state.redirect){

            // if(this.state.redirect === 'expiration'){
            //     return(
            //         <Redirect
            //             to={{
            //                 pathname: "/expiration"
            //                 // state: { referrer: props.location.pathname }
            //             }}
            //         />
            //     )    
            // }
            return(
                <Redirect
                    to={{
                    pathname: this.state.redirect,
                    // search: window.location.search+"?return="+window.location.pathname,
                    // state: { referrer: props.location.pathname }
                }}
            />
            )
        }

        const provinceOptions = [
            { label: "Québec", value: "QC" },
            { label: "Ontario", value: "ON" },
          ];
      

        const columns = [
            // {
            //     Header: 'Lead #',
            //     accessor: 'id',
            // },
            {
                Header: this.genLang('created'),
                accessor: 'created'          
            },
            {
                Header: this.genLang('subject'),
                accessor: 'subjectHover'
            
            },
            {
                Header: this.genLang('status'),
                accessor: 'tableStatus',
            },
            {
                Header: 'User',
                accessor: 'user',
            },
            {
                Header: "Alert",
                accessor: "alertIcons"
            },
            {
                Header: 'Priorité',
                accessor: 'priorityIcons',
            },
            {
                Header: this.genLang('domain'),
                accessor: 'domaineLead',
            },
            {
                Header: "Province",
                accessor: "province",
            },
            {
                Header: 'Edit',
                accessor: 'editIcon'
            },
        ];
        
        const columnsRappels = [
            {
                Header: 'Lead #',
                accessor: 'id',
            },
            {
                Header: 'User',
                accessor: 'user',
            },
            {
                Header: 'Contact',
                accessor: 'contact',
            },
            {
                Header: this.genLang('domain'),
                accessor: 'domaineLead',
            },
            {
                Header: 'Date',
                accessor: 'event_start',
            },
            {
                Header: this.genLang('edit'),
                accessor: 'editIcon'
            }
        ];

        const genLang=this.genLang;

        const actionOptions = [
            // {label: "Aucun/None", value: ''},
            {label: genLang('attributed'), value: '1'},
            {label: genLang('supported'), value: '2'},
            {label: genLang('closed'), value: '3'},
            {label: genLang('cancelled') , value: '5'},
            {label: genLang('automaticDistrubution'), value: '0'},
            {label: genLang('notAutomaticDistribution'), value: '4'},
            {label: genLang('ecommercesms'), value: '8'}, 
          ];
          const otherFilters = [
            // {label: "Aucun", value: ''},
            {label: "Lead #", value: 'invId'},
            {label: "Client #", value: 'invCliId'},
            {label: "Ticket #", value: 'invTicket'},
            {label: "Email", value: 'invEmail'},
            {label: "Contact #", value: 'invPhone'},
            {label: "Langue", value: 'invLang'},
            {label: "Province", value: 'invProvince'},
        ]
          const thermoOptions = [
            {label: genLang('cold'), value: '1'},
            {label: genLang('warm'), value: '2'},
            {label: genLang('hot'), value: '3'},
          ];
          //handleSelectLeadStatus
          const objBig  = this.state.dataRawList;
          const showEditLead=this.state.showEditLead;
        // console.log("0000dataRawList-dataRawList", objBig);

        return (
        <>
        <Row onClick={() =>  console.log(this.state)}>
            <Col md={12} style={{paddingBottom: '1.5rem'}}>
                <h1 style={{ color: "#0052BB" }} className='text-align-center m-t-15 element-header'>Admin Leads</h1>
            </Col>
        </Row>
        <Row>
            <Col md={12} >

                <DashboardElement
                    col={12}
                    title={<>Leads <FontAwesomeIcon icon={faTags} /></>}
                    toggle={false}
                >
                    <Row>
                        <Col md={12}>
                            <Nav
                                style={{
                                    paddingTop: "0",
                                    marginBottom: "10px"
                                }}
                            >
                            {/* <div>{`${this.genLang('numberOfLeads')} ${this.state.data.length ? this.state.data.length : this.genLang('downloading')}`}</div> */}
                            {this.state.tableView === 'lead' ? 
                                <div>{`${this.genLang('numberOfLeads')} ${this.state.data.length}`}</div> 
                                : 
                                <div>{`${this.genLang('numberOfReminders')} ${this.state.rappels.length}`}</div>
                            }
                            </Nav>
                            <div style={{marginBottom: '0.8rem'}}>
                                <Button onClick={() => this.toggleTableView('lead')}
                                style={{marginRight: '0.5rem', borderRadius: '40px'}}
                                    color='primary' outline={this.state.tableView !== 'lead'}
                                >
                                    Leads
                                </Button>
                                <Button onClick={() => this.toggleTableView('rappel')}
                                    color='primary'
                                    style={{borderRadius: '40px'}}
                                    outline={this.state.tableView !== 'rappel'}
                                >
                                    {this.genLang('reminders')}
                                </Button>
                            </div>
                            {/* SEARCH BAR */}
                            {!this.state.selectedCheckboxes.length && <>
                            <h6 style={{fontWeight: '700', color: "#0052bb"}}>Filtres</h6>
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={3}>
                                    <Label>Status</Label>
                                    <Select 
                                        options={actionOptions}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={this.state.filterLoading === true}
                                        isClearable
                                        value={actionOptions.find(i => i.value === this.state.filterStatus) || ""}
                                        onChange={e => this.handleFilterSelectChange(e, 'status')}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Label>Agent</Label>
                                    <Select 
                                        options={this.state.agentOptions}
                                        isDisabled={this.state.filterLoading === true} 
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        isClearable
                                        value={this.state.agentOptions.find(i => i.agentId === this.state.filterAgent) || ""}
                                        onChange={e => this.handleFilterSelectChange(e, 'agent')}
                                    />
                                </Col>
                                <Col md={3}>
                                <Label>Domaine</Label>
                                    <Select 
                                        options={this.state.domaineOptions}
                                        isDisabled={this.state.filterLoading === true}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        isClearable
                                        value={this.state.domaineOptions.find(i => i.name === this.state.filterDomaine) || "" }
                                        onChange={e => this.handleFilterSelectChange(e, 'domaine')}
                                    />
                                </Col>
                                <Col md={3}>
                          <Label>Province</Label>
                          <Select
                            options={provinceOptions}
                            isDisabled={this.state.filterLoading === true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isClearable
                            value={
                              provinceOptions.find(
                                (i) => i.value === this.state.filterProvince
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "province")
                            }
                          />
                        </Col>
                                
                                {/* <Col md={2}>
                                    
                                <Button
                                    outline
                                    // style={{marginTop: "1rem"}}
                                    color="primary"
                                    block
                                >{this.genLang('search')}
                                </Button>
                                <div>
                                <Button
                                        outline
                                        // style={{marginTop: '1rem'}}
                                        color="primary"
                                        block
                                    >{this.genLang('erase')}
                                    </Button>
                                    </div>
                                </Col> */}
                            </Row>

                            <Row>
                            <Col md={3}>
                                <Label>Autre</Label>
                                    <Select 
                                        options={otherFilters}
                                        isDisabled={this.state.filterLoading === true}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        isClearable
                                        value={otherFilters.find(i => i.value === this.state.filterOther) || "" }
                                        onChange={e => this.handleFilterSelectChange(e, 'other')}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Label>Value</Label>
                                    <Input 
                                        value={this.state.filterOtherText}
                                        disabled={this.state.filterLoading === true}
                                        onChange={e => this.setState({filterOtherText: e.target.value})}
                                    />
                                </Col>
                                <Col md={3}>
                                <Button
                                    outline
                                    disabled={this.state.filterLoading === true}
                                    onClick={() => this.fetchFilterData()}
                                    style={{marginTop: "1.6rem", height: '37px'}}
                                    color="primary"
                                    block
                                >{`${this.state.filterLoading === true ? this.genLang('loading') : this.state.filterAgent === '' && this.state.filterStatus === ''&& this.state.filterOther === ''&& this.state.filterOtherText === '' ? this.genLang("refresh") : this.genLang('search')} `}
                                {this.state.filterLoading === true ? <Spinner size="sm" /> : 
                                    <FontAwesomeIcon icon={this.state.filterAgent === '' && this.state.filterStatus === ''&& this.state.filterOther === ''&& this.state.filterOtherText === '' ? faRedo : faSearch}/>}
                                </Button>
                                </Col>

                                <Col md={3}>
                                <Button
                                        outline
                                        disabled={this.state.filterLoading === true || this.state.filterAgent === '' && this.state.filterStatus === ''&& this.state.filterOther === ''&& this.state.filterOtherText === '' && this.state.filterDomaine === ''}
                                        onClick={() => {
                                            this.setState({
                                                filterAgent: "",
                                                filterOther: '',
                                                filterStatus: "",
                                                filterDomaine: "",
                                                filterOtherText: ""
                                            }, () => this.fetchFilterData());
                                        }}
                                        style={{marginTop: '1.6rem', height: '37px'}}
                                        color="primary"
                                        block
                                    >{`${this.genLang('empty')} `}<FontAwesomeIcon icon={faEraser}/>
                                    </Button>
                                </Col>
                            </Row>
                            </>}
                            {this.state.selectedCheckboxes.length ? 
                            <ActionBar 
                                genLang={this.genLang} 
                                trad={this.props.trad} 
                                lang={this.props.lang} 
                                agentOptions={this.state.agentOptions} 
                                domaineOptions={this.state.domaineOptions}
                                selectedCheckboxes={this.state.selectedCheckboxes}
                                fetchFilterData={this.fetchFilterData}
                                handleClearCheckboxes={this.handleClearCheckboxes}
                            /> : ""}
                            {/* <div style={{position:'absolute', zIndex :'99', width:'100%'}}>
                            <Row>
                                <Col md={4}>
                                <>
                                <Select
                                    options={actionOptions}
                                    placeholder={"Select an Action Status"}
                                    
                                    onChange={this.handleSelectLeadStatus}
                                /></>
                                </Col>
                                <Col md={4}>

                                </Col>
                                <Col md={3}>
                                    <Select
                                        //isDisabled={tableView === 'lead' && selectedLead.status !== "1"}
                                        options={agentOptions}
                                        placeholder={"Choisir Agent/Select an agent"}
                                        //value={agentOptions.find((i) => i.agentId === selectedLead.agentId)} selectedAgent  
                                        value={agentOptions.find((i) => i.agentId === selectedAgent.agentId)} 
                                        onChange={(e) => {
                                            handleSelectChangeagent(e, "selectedAgent");   
                                        }}
                                    />
                                </Col>
                                <Col md={1}>
                                 
                                </Col>
                            </Row>
                            </div> */}
                             {/* &nbsp;<br/> &nbsp;<br/> 
                            <div style={{marginTop:'5px',marginBottom:'5px', padding:'5px',backgroundColor:'orange', width:'100%'}}>
                                <span>Filtrer serveur/Server filtering</span>  &nbsp;  
                                <select name="fieldName2" id="fieldName2" onChange={this.handleChangeStatus} value={this.state.fieldName2}>
                                    <option value="" data-value="" data-status="">Choisir/Select Champs/Field</option>
                                    <option value="invId" data-value="invId" data-status="">#Lead/Lead#</option>
                                    <option value="invCliId" data-value="invCliId" data-status="">#Client/Client#</option>
                                    <option value="invTicket" data-value="invTicket" data-status="">#Ticket/Ticket#</option>
                                    <option value="invEmail" data-value="invEmail" data-status="">Email</option>
                                    <option value="invPhone" data-value="invPhone" data-status="">#Telephone/Phone#</option>
                                    <option value="invConId" data-value="invConId" data-status="">#Contact/Contact#</option>
                                    <option value="invAgId" data-value="invAgId" data-status="">#Agent/Agent#</option>

                                    <option value="invStatus-1" data-value="invStatus" data-status="1">status: {genLang('attributed')}</option>
                                    <option value="invStatus-2" data-value="invStatus" data-status="2">status: {genLang('supported')}</option>
                                    <option value="invStatus-2" data-value="invStatus" data-status="3">status: {genLang('closed')}</option>
                                    <option value="invStatus-3" data-value="invStatus" data-status="5">status: {genLang('cancelled')}</option>
                                    <option value="invStatus-4" data-value="invStatus" data-status="0">status: {genLang('automaticDistrubution')}</option>
                                    <option value="invStatus-5" data-value="invStatus" data-status="4">status: {genLang('notAutomaticDistribution')}</option>
                                    <option value="invStatus-" data-value="invStatus" data-status="">status: {genLang('legacyBackup')}</option>

                                </select>   &nbsp; 
                                <input name="fieldVal" id="fieldVal" onChange={this.handleChange} value={this.state.fieldVal} />
                                &nbsp; 
                                <button type="button" className="btn btn-primary" onClick={this.loadNextPageRedata}>Charger/Load</button>  
                            </div> */}

                            {/* <div> &nbsp;<br/> &nbsp;<br/>  */}
                            {/* &nbsp;<br/> &nbsp;<br/> &nbsp;<br/> */}
                            {/* </div> */}
                            {/* Nombre de Leads
                            <div>
                                <lable className="reactSelectLabel">React select</lable>
                                <Select
                                    className="reactSelect"
                                    name="subfilters"
                                    placeholder="Filters"
                                    value={values.selectedOption}
                                    options={options}
                                    onChange={handleMultiChange} 
                                />
                            </div>
                            <div>Filtrer par 
                                
                                {`${this.genLang('numberOfLeads')} ${this.state.data.length ? this.state.data.length : this.genLang('downloading')}`}</div> */}
                        </Col>
                    </Row>
                    <Table
                        options={{headerStyle: {zIndex: 1}}}
                        genLang={this.genLang}
                        trad={this.props.trad}
                        lang={this.props.lang}
                        showCheckboxes={true}
                        selectedCheckboxes={this.state.selectedCheckboxes}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleSelectAllCheckboxes={this.handleSelectAllCheckboxes}
                        data={
                            this.state.tableView === 'lead' ?
                            _.map(this.state.data, (elt, i) => {
                                // // console.log(elt)
                                // if (elt.agent === '-1') elt.agent = null
                                elt.priorityIcons = <PriorityIconsToggle 
                                    priority={elt.thermo}
                                    thermo={elt.thermo}
                                    trad={this.props.trad}
                                    genLang={this.genLang}
                                    lang={this.props.lang}
                                    id={elt.id}
                                    handleChangePriority={this.handleChangePriority}
                                />
                                elt.alertIcons = <AlertIconsToggle 
                                priority={elt.priority}
                                    thermo={elt.priority}
                                    trad={this.props.trad}
                                    genLang={this.genLang}
                                    lang={this.props.lang}
                                    id={elt.id}
                                    handleChangeAlert={this.handleChangeAlert}
                                />
                                elt.tableStatus = this.handleTableStatus(elt)
                                elt.subjectHover = <div id={`a${elt.id}`} style={{cursor: 'pointer'}} onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    this.setState({selectedLead: {...elt}})}
                                }
                                >
                                    {elt.subject}
                                    <UncontrolledPopover 
                                        trigger='hover'
                                        target={`a${elt.id}`}
                                        placement="left"
                                        style={{minHeight: '500px', minWidth: "400px", maxWidth: '800px', maxHeight: '800px'}}
                                    >
                                        <div style={{width: '400px', height: '500px', backgroundColor: 'white'}}>
                                        <PopoverHeader>{`Lead #${this.state.selectedLead.id}`}</PopoverHeader>
                                        <PopoverBody style={{maxWidth: '600px', maxHeight: '600px'}}>
                                            {this.state.selectedLead && 
                                            <div
                                            className="messageArchiveContainer"
                                            style={{ marginBottom: "1rem", maxHeight: '400px', maxWidth: '400px', overflowY: 'scroll' }}
                                          >
                                            {this.renderLeadInfo()}
                                            <h6>
                                                {this.state.selectedLead.definition === null ? " " : this.state.selectedLead.definition.ticket_latest_public_comment_author_name !== null ? `Note par: ${this.state.selectedLead.definition.ticket_latest_public_comment_author_name}` : " "}
                                            </h6>
                                            {this.state.selectedLead.definition === null ? 
                                            <div
                                              className="messageArchive"
                                              dangerouslySetInnerHTML={{ __html: "Aucun Commentaire"}}
                                            /> : this.state.selectedLead.definition.ticket_latest_public_comment !== null ?
                                            this.state.selectedLead.definition.ticket_latest_public_comment && <div
                                              className="messageArchive"
                                              dangerouslySetInnerHTML={{ __html: this.state.selectedLead.definition.ticket_latest_public_comment.replaceAll('\n', '<br/>')}}
                                            ></div> : ''}
                                          </div>
                                            }
                                        </PopoverBody>
                                        </div>
                                    </UncontrolledPopover>
                                </div>
                                elt.editIcon = 
                                <div 
                                    style={{whiteSpace: 'nowrap'}} 
                                >
                                    <IconButton
                                        icon={faEdit}
                                        tooltip={this.genLang('edit')}
                                        style={{color: "#0076cf"}}
                                        onClick={() => this.openChangeLeadModal(elt)}
                                    />
                                </div>
                                
                                return elt;
                            }) : this.state.rappels.map(i => {
                                return {...i, editIcon: <div style={{whiteSpace: 'nowrap'}}>
                                    <IconButton
                                        icon={faInfoCircle}
                                        tooltip={this.genLang('viewLead')}
                                        style={{color: "#0076cf"}}
                                        onClick={() => this.openChangeLeadModal(i)}
                                    />
                             </div>}
                            })
                        }
                        columns={this.state.tableView === 'lead' ? columns : columnsRappels}
                    />
                </DashboardElement>
            </Col>
        </Row>

        {showEditLead?
        <EditLeadModal
            tableView={this.state.tableView} 
            showEditLead={this.state.showEditLead}
            close={this.closeChangeLeadModal}
            loading={this.state.loading}
            error={this.state.error}
            selectedLead={this.state.selectedLead}
            agentOptions={this.state.agentOptions}
            domaineOptions={this.state.domaineOptions}
            handleSelectChange={this.handleSelectChange}
            handleSelectActionChange={this.handleSelectActionChange}
            handleSetValueChange={this.handleSetValueChange}
            handleSubmit={this.handleSubmit}
            handleCloseLead={this.handleCloseLead}
            genLang={this.genLang}
            trad={this.props.trad}
            lang={this.props.lang}
            macrosOptions={this.state.macrosOptions}
            getDueDate={this.getDueDate}
        />:""
    }
        <Loader  openx={this.state.isLoaderOpen}  />
        </>
        )
    }
}